import React, {useState, useEffect, useContext} from 'react';

import axios from 'axios';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DotLoader from 'react-spinners/DotLoader';

// components
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import DataCard from '../components/DataCard';
import ReportTable from '../components/ReportTable';
import NotificationBar from '../components/NotificationBar';
import Chart from '../components/Chart';

// helpers ^_^
import auth from '../helpers/Auth';
import global from '../helpers/Global';
import {Context} from '../contexts/Context';

import {CustomerSignUpsURL} from '../helpers/Urls';

const CustomerSignUps = () => {
  const {updateDateUpdated} = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(moment().subtract(0, 'days'));

  const [statistics, setStatistics] = useState([]);

  const [chartData, setChartData] = useState([]);

  const [updateState, setUpdateState] = useState(false);

  const [pickerLabel, setPickerLabel] = useState('Last 30 days');

  const [boxData, setBoxData] = useState({
    average_sign_ups: 0,
    card_on_file_customers: 0,
    new_customers: 0,
    new_cards_on_file: 0,
  });

  // Handle picker label and set Date Range
  const handleDateRangeEvent = (e, picker) => {
    var start = picker.startDate,
      end = picker.endDate;

    setStartDate(start);
    setEndDate(end);

    fetchStatistics(start, end);

    if (
      start.format('MMM DD YYYY') ===
        moment().subtract(7, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 7 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(30, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 30 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(60, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 60 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(90, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 90 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(180, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 180 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(365, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 365 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(1, 'year').startOf('year').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') === moment().subtract(1, 'year').endOf('year')
    ) {
      setPickerLabel('Last Year');
    } else {
      setPickerLabel(
        start.format('MMM DD YYYY') + ' - ' + end.format('MMM DD YYYY')
      );
    }
  };

  // Date Picker Range Values
  const ranges = {
    'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
    'Last 30 Days': [
      moment().subtract(30, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 60 Days': [
      moment().subtract(60, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 90 Days': [
      moment().subtract(90, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 180 Days': [
      moment().subtract(180, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 365 Days': [
      moment().subtract(365, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
  };

  const ShowWelcomeComponent = () => {
    if (typeof window !== `undefined`) {
      if (!JSON.parse(localStorage.getItem('visited'))) {
        localStorage.setItem('visited', JSON.stringify(true));
        return (
          <NotificationBar message="You'll stay logged in for 30 days in this browser unless you logout or clear your cookies" />
        );
      } else {
        return false;
      }
    }
  };

  const tableHeaders = [
    {title: 'Date'},
    {title: 'Signed up'},
    {title: 'Created first plan'},
    {title: 'Pre-live'},
    {title: 'Free trial'},
    {title: 'Paying customer'},
  ];

  const fetchStatistics = async (start, end) => {
    setIsLoading(true);

    let getTableDataURL =
      CustomerSignUpsURL.custom +
      '?start=' +
      start.format('YYYY-MM-DD') +
      '&end=' +
      end.format('YYYY-MM-DD');

    let getNewFreeTrialCntURL =
      CustomerSignUpsURL.newFreeTrials +
      '?start=' +
      start.format('YYYY-MM-DD') +
      '&end=' +
      end.format('YYYY-MM-DD');

    const reverseSort = (data) => {
      const _data = data.map((values) => values);
      setChartData(_data.reverse());
    };

    const [tableResult, newFreeTrialResult] = await Promise.all([
      axios.get(getTableDataURL + '&order=DESC', auth.getAuthConfig()),
      axios.get(getNewFreeTrialCntURL + '&order=DESC', auth.getAuthConfig()),
    ]);

    let statData = tableResult.data;
    statData.new_free_trials = newFreeTrialResult.data.new_free_trial_count;
    setStatistics(statData);
    updateDateUpdated(statData[0]?.date);
    setBoxData(computeMean(statData));
    reverseSort(statData);

    setIsLoading(false);
  };

  const computeMean = (data) => {
    let sumSignups = 0;
    let sumCardOnFileCustomers = 0;

    if (data.length > 0) {
      data.forEach((stats) => {
        sumSignups += stats.average_signups;
        sumCardOnFileCustomers += stats.card_on_file_customers
          ? stats.card_on_file_customers
          : 0;
      });
    }
    let newCustomers = data[0].signed_up - data[data.length - 1].signed_up;
    let avgSignups = sumSignups / data.length;
    let avgCardOnFile = sumCardOnFileCustomers / data.length;

    return {
      average_sign_ups: avgSignups > 0 ? avgSignups : 0,
      card_on_file_customers: avgCardOnFile > 0 ? avgCardOnFile : 0,
      new_customers: newCustomers > 0 ? newCustomers : 0,
      new_cards_on_file: data.new_free_trials > 0 ? data.new_free_trials : 0,
    };
  };

  useEffect(() => {
    fetchStatistics(startDate, endDate);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <ShowWelcomeComponent />
      <Header isLoggedIn={true} />
      <div className="home body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="filter-options">
            <DateRangePicker
              onApply={handleDateRangeEvent}
              initialSettings={{
                startDate: startDate,
                endDate: endDate,
                ranges: ranges,
                maxDate: moment().subtract(1, 'days'),
                alwaysShowCalendars: true,
              }}
            >
              <button className="filter-head dropdown-toggle btn btn-secondary">
                {pickerLabel}
              </button>
              {/* <i className="fal fa-chevron-down"></i> */}
            </DateRangePicker>
            <button
              onClick={() => {
                setUpdateState(!updateState);
              }}
              className="btn-orange"
            >
              <img
                className="icon-refresh"
                src={require('../assets/images/refresh.svg')}
                alt="refresh-report"
              />
              Update Report
            </button>
          </div>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <>
              <Chart chartData={chartData} />
              <div className="data-card-holder">
                <DataCard
                  heading="Average sign-ups"
                  dataValue={global.nullChecker(
                    Math.round(boxData.average_sign_ups)
                  )}
                />
                <DataCard
                  heading="Free trial customers"
                  dataValue={
                    global.nullChecker(boxData.card_on_file_customers) + '%'
                  }
                />
                <DataCard
                  heading="New customers"
                  dataValue={global.nullChecker(
                    Math.round(boxData.new_customers)
                  )}
                />
                <DataCard
                  heading="New Free trial"
                  dataValue={global.nullChecker(
                    Math.round(boxData.new_cards_on_file)
                  )}
                />
              </div>
              <ReportTable
                statistics={statistics}
                tableHeaders={tableHeaders}
                chartType="customerSignups"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerSignUps;
